<template>
  <div v-if="imageLeft || imageRight" class="relative -z-10 hidden select-none md:visible md:block">
    <div class="absolute inset-0 h-[650px] overflow-hidden">
      <div class="h-container">
        <div class="h-gap grid grid-cols-12">
          <div v-if="imageLeft" class="relative col-span-4 md:col-span-1">
            <OnImg class="absolute right-0 top-0 h-[650px] w-[784px] max-w-[784px]" :src="imageLeft" sizes="lg:800px" alt="" />
          </div>
          <div v-if="imageRight" class="relative col-span-4 col-start-9 md:col-span-3 md:col-start-12">
            <OnImg class="absolute left-0 top-0 h-[650px] w-[784px] max-w-[784px]" :src="imageRight" sizes="lg:800px" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import OnImg from '~/components/on-img/OnImg.vue'
import { useSiteHatsState } from '~/composables/states'

// HACK: Uses side effect
const siteHat = useState('randomSitehat', () => useRandomElementFromNullableArray(useSiteHatsState().value))
const imageLeft = computed(() => siteHat.value?.left || null)
const imageRight = computed(() => siteHat.value?.right || null)
</script>
